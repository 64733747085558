.wishlist-container {
  /* display: flex;
  align-items: center; */
}

.is-not-logged-in-box {
  height: var(--main-content-div-full-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.is-not-logged-in-box h4 {
  font-size: 20px;
  font-weight: 600;
  color: #282c3f;
  text-transform: uppercase;
  margin-bottom: var(--spacer-md);
}

.is-not-logged-in-box p {
  font-size: 18px;
  color: #94989f;
}

.is-not-logged-in-box a {
  border: 1px solid var(--accent-blue-color);
  border-radius: var(--border-radius-sm);
  font-size: 18px;
  font-weight: 600;
  padding: 14.5px 51px;
  color: var(--accent-blue-color);
}

.wishlist-icon {
  display: block;
  margin: 45px auto 65px auto;
  width: 99px;
  height: 105px;
  background-position: -1163px 0;
}

/* -----WISHLIST PRODUCTS----- */
.is-logged-in-box {
  padding: 50px 20px;
  width: 92%;
  margin: 0 auto;
}

.is-logged-in-box h4 {
  font-size: 18px;
  font-weight: 700;
  color: #282c3f;
}

.is-logged-in-box h4 span {
  font-weight: 400;
}

.wishlisted-products-container {
  justify-content: space-between;
  gap: var(--spacer-xl);
  flex-wrap: wrap;
}

.product-wrapper {
  position: relative;
  transition: all 2s ease;
  flex: 1 0 220px;
  margin: 40px 0 0 0px;
  border: 1px solid #e9e9eb;
  max-width: 220px;
}

.product-wrapper .close {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 20px;
  height: 24px;
  width: 24px;
  background-color: rgb(255 255 255 / 60%);
  border: 1.2px solid #d4d5d9;
  text-align: center;
  font-size: 12px;
  color: #545866;
  z-index: 1;
}

.product-details-wrapper {
  min-height: 400px;
}

.item-out-of-Stock-img img {
  opacity: 0.6;
  filter: grayscale(1);
}

.item-out-of-Stock-text {
  position: absolute;
  background: #fff;
  color: #ff5722;
  text-transform: uppercase;
  top: -24px;
  left: calc(50% - 70px);
  padding: 5px 20px 0;
  font-size: 16px;
}

.product-details-wrapper img {
  width: 100%;
  cursor: pointer;
  min-height: 294px;
  transition: all 2s ease;
  opacity: 1;
}

.product-details {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  width: 100%;
  background: #fff;
  font-size: 14px;
  text-align: center;
  color: #696b79;
}

.product-title {
  font-size: 16px;
  color: #282c3f;
  text-align: left;
  margin: 10px 10px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.price-box {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
}

.discounted-price {
  font-weight: 700;
  padding: 0 3px 0 0;
  font-size: 16px;
  color: #282c3f;
}

.mrp {
  text-decoration: line-through;
  opacity: 0.7;
  font-size: 12px;
  padding: 0 3px;
  color: #282c3f;
}

.discounted-text {
  color: #ff905a;
  padding: 0 0 0 3px;
  font-size: 12px;
  font-weight: 700;
}

.move-to-bag-action {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 14px 0;
  color: #ff3e6c;
  font-weight: 700;
  display: block;
  margin-top: 10px;
  border-top: 1px solid #e9e9eb;
}
