.home section h2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: var(--primary-txt-color);
  letter-spacing: var(--letter-spacing-lg);
  font-size: var(--font-size-xxl);
  margin: var(--spacer-xl) auto;
  max-height: 5em;
  font-weight: 700;
}

.home-special-offers {
  padding: var(--spacer-0) var(--spacer-0) var(--spacer-md);
  height: 500px;
}

.carousel-home-special-offers {
  height: 500px;
}

.home-special-offers-aspect-ratio-box {
  padding-top: calc(100% * (1 / 2.3));
}

.banner-container {
  width: 100%;
}

/* -------------------
SALES ADS BEGINS
----------------------*/

.sales-ads-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
}

.sales-ads-container h3 {
  color: var(--secondary-txt-color);
}

.card-br-small {
  border-radius: var(--border-radius-sm);
  flex: 1 0 0;
  position: relative;
}

.sales-ads-category-box {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: var(--spacer-md) auto var(--spacer-lg) auto;
}

.sales-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--primary-txt-color);
}

.sales-category small {
  align-self: flex-start;
}

.sales-category-img-box {
  width: 100%;
  height: 100%;
  margin-bottom: var(--spacer-xs);
}

.sales-category-img-box img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}

.coupons-and-sign-in {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.coupons {
  flex-grow: 3;
}

.coupons-and-sign-in button {
  display: block;
  width: 100%;
  padding: var(--spacer-sm);
  margin-top: var(--spacer-xl);
  border: 0;
  border-radius: var(--border-radius-sm);
  background: var(--accent-blue-color);
  color: var(--white);
}

.coupons-corner-img {
  width: 330px;
  margin-top: var(--spacer-sm);
}

.coupons-corner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.see-more {
  margin-bottom: var(--spacer-md);
  position: absolute;
  bottom: 0;
  width: 100%;
}
/* -------------------
SALES ADS ENDS
----------------------*/

/* ------------------
Shop by category begins
---------------------*/
.shop-by-category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.shop-by-category-box {
  flex: 0 0 16.5%;
}

/* ------------------
Shop by category ends
---------------------*/
