.no-of-items-selected-indicator-box {
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 26px;
  padding: 0 12px 0 10px;
  color: #282c3f;
}

.no-of-items-selected-indicator-box label {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  flex: 1 1 50%;
}

.cart-bulk-action button {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  color: #535766;
  padding: 6px 24px 6px 0;
  height: 32px;
}

.cart-bulk-action button:last-child {
  padding: 6px 0 6px 24px;
  border-left: 1px solid #d4d5d9;
}

.cart-items-container {
}
