/* DropdownMenu.css */
.dropdown {
  position: relative;
  display: inline-block;
  background: #f5f5f6;
  color: #282c3f;
  cursor: pointer;
  border-radius: 2px;
  min-width: 120px;
}

.dropdown-toggle {
  display: inline-block;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  padding: 2px 8px;
  background: #f5f5f6;
  color: #282c3f;
  font-weight: 700;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  list-style: none;
  padding: 0;
}

.dropdown-option {
  padding: 10px;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-option:hover,
.dropdown-option.selected {
  background-color: #f1f1f1;
}
