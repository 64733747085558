.svg-double-star {
  margin-left: var(--spacer-xs);
}

.index-header {
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 7px;
  color: var(--secondary-txt-color);
}

.view-details {
  font-size: var(--font-size-md);
  font-weight: 700;
  color: var(--accent-pink-color-dark);
  margin-top: var(--spacer-sm);
  cursor: pointer;
}
