.search-bar {
  display: flex;
  flex-direction: row-reverse;
  min-width: 100px;
}

.search-bar-input {
  font-size: var(--font-size-md);
  color: var(--primary-txt-color-light);
  margin: var(--spacer-0);
  padding: var(--spacer-sm);
  outline: 0;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  border-left: 0;
  flex-grow: 1;
  background: var(--secondary-bg-color);
}

.search-bar-icon {
  padding: var(--spacer-sm);
  box-sizing: content-box;
  text-align: center;
  color: var(--primary-txt-color-light);
  font-size: var(--font-size-sm);
  background: var(--secondary-bg-color);
  border: 1px solid var(--secondary-bg-color);
  border-right: 0;
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
}

input.search-bar-input:focus,
input.search-bar-input:focus + span.search-bar-icon {
  background-color: var(--white);
  border-color: var(--primary-border-clr);
}
