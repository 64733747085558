.products-container {
  flex: 1 0 0;
}

.v-filters-container {
  flex: 0 0 18%;
  padding: 0;
}

.products-wrapper {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
