.checkout-payment-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 50px;
}

.payment-left {
  flex: 0 0 65%;
  padding: 24px 16px;
  border-right: 1px solid #eaeaec;
}

.payment-right {
  flex: 0 0 35%;
  padding: 0 16px;
}

.payment-left h4 {
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.payment-mode-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #eaeaec;
  border-radius: 4px;
}

.payment-modes {
  flex: 0 0 35%;
}

.payment-mode-details {
  flex: 0 0 65%;
  padding: 24px 32px;
}

.payment-modes ul {
  background-color: #f4f4f5;
}

.payment-modes ul li {
  border-bottom: 1px solid #eaeaec;
  border-right: 1px solid #eaeaec;
  border-left: 5px solid #f4f4f5;
  border-top: 0;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
}

.payment-modes ul li.selected {
  background-color: #fff;
  border-left-color: #ff3f6c;
  border-right: 0;
  color: #ff3f6c;
}

.payment-modes ul li span {
  padding-left: 10px;
}

.payment-mode-details .header {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 32px;
}

/* Cash on Delivery */
.cod-container button {
  width: 100%;
  text-align: center;
  background: #ff3f6c;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 48px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  margin-top: 32px;
  text-transform: uppercase;
}
