header {
  position: sticky;
  top: 0px;
  z-index: var(--z-index-header);
}

:global .row-base-center,
:global .box-center {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

:global .flex-center-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

:global .flex-stretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}

:global .form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 0;
  font-size: 13px;
  line-height: 1.42857143;
  color: #3e4152;
  background-color: #fff;
  background-image: none;
  border: none;
}
