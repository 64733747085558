.navbar {
  background-color: var(--primary-bg-color);
  border-bottom: 1px solid var(--primary-border-clr);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 93%;
  margin: var(--spacer-0) auto;
  padding: var(--spacer-sm) var(--spacer-md);
  height: var(--height-header);
}

.navbar-brand {
  flex: 0 0 5%;
}

.navbar-logo {
  width: 60px;
  height: 60px;
  display: block;
}

.navbar-links {
  flex: 0 0 50%;
}

.navbar-links-container {
  display: flex;
}

.nav-link {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--font-size-md);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-sm);
  color: var(--secondary-txt-color);
  padding: 0 var(--spacer-md);
  border-bottom: none;
}

.nav-link a {
  color: var(--secondary-txt-color);
}

.navbar-search {
  flex: 0 0 25%; /*NOTE: This is a shorthand property that sets flex-grow, flex-shrink, and flex-basis.*/
}

.navbar-actions {
  flex: 0 0 13%;
}

.navbar-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-action {
  text-decoration: none;
  color: var(--primary-txt-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  position: relative;
  height: 60px;
  margin: 0 5px;
}

.navbar-action-profile-box {
  position: relative;
}

.navbar-action-profile-box:hover .profile-user-actions {
  visibility: visible;
  opacity: 1;
}

.profile-user-actions {
  width: 250px;
  left: -100px;
  top: 65px;
  position: absolute;
  padding: 25px 20px 15px;
  z-index: 9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  transition:
    opacity 0.2s ease-out,
    visibility 0.2s ease-out;
  background-color: #fff;
  color: #3e4152;
  border: 1px solid #f5f5f6;
  visibility: hidden;
  opacity: 0;
  margin: -2px 0;
}

.profile-user-actions::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 4px;
  background-color: var(--accent-pink-color);
  transform: translateX(-50%); /* Centers the pseudo-element horizontally */
}

.profile-user-info {
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 10px; */
}

.profile-user-info-title {
  font-weight: 700;
  height: 20px;
}

.login-signup-btn {
  padding: 8px 20px;
  margin: 20px 0 10px 0;
  border: 1px solid #eaeaec;
  border-radius: 2px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #ff3f6c;
  font-weight: 700;
}

.login-signup-btn:hover {
  border: 1px solid #ff3f6c;
}

.user-name,
.user-email-or-mobile {
  margin-bottom: 6px;
}

.user-name {
  margin-bottom: 10px;
  font-weight: 700;
}

.user-name span {
  text-transform: uppercase;
}

.user-profile-links-box {
  padding: 10px 0;
  border-top: 1px solid #eaeaec;
}

.user-profile-links-box a,
.logout-btn {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #3e4152;
  padding-top: 10px;
}

.logout-btn {
  padding: 10px 0 0 0;
}

.user-profile-links-box a:hover,
.logout-btn:hover {
  font-weight: 700;
}

.navbar-action-label {
  color: var(--secondary-txt-color);
  font-size: var(--font-size-sm);
  font-weight: 700;
  padding-top: var(--spacer-sm);
  line-height: 6px;
}

.sprites-headerBag {
  width: 16px;
  height: 16px;
  background-position: -344px -58px;
}

.cart {
  position: relative;
}

.cart-items-badge {
  position: absolute;
  left: 13px;
  top: 5px;
  width: 18px;
  height: 18px;
  border-radius: var(--border-radius-circle);
  background-color: var(--accent-pink-color);
  color: var(--white);
  font-size: var(--font-size-sm);
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}
