.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 250px;
}

.toast {
  position: relative;
  right: 0;
  bottom: 20px;
  margin-bottom: 20px;
  background-color: #f44336;
  color: white;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateX(110%);
  transition: transform 300ms ease-in-out;
}

.toast-container .toast.show {
  transform: translateX(0);
}

.toast:last-child {
  margin-bottom: 0px;
}

.toast span {
  margin-right: 10px;
}

.toast-container .toast button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
}

.toast.success {
  background-color: #4caf50; /* green for success */
}
