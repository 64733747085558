.carousel {
  display: flex;
  width: 100%;
  height: 100%; /*set height in parent component to generalize it*/
  position: relative;
}
.carousel-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-card {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-20px);
  transition: 0.2s ease-in-out;
}

.carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 85% 0%;
}

.card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 40px 30px;
  align-items: self-end;
  justify-content: center;
}

.card-title {
  color: var(--white);
  font-size: var(--font-size-xxl);
}

.carousel-card-active {
  opacity: 1;
  transform: translateX(0px);
  pointer-events: visible;
}

.indicators {
  font-size: calc(var(--font-size-md) / 2);
  color: var(--secondary-border-clr);
  position: absolute;
  bottom: 33px;
  left: 50%;
  translate: -50% 50%;
  display: flex;
  gap: var(--spacer-xxs);
}

.indicator-active {
  color: var(--accent-pink-color-dark);
  transform: scale(1.2);
  transition: 0.3s;
}

.indicator:hover {
  transform: scale(1.3);
}

.carousel-slide-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide-actions .carousel-arrow-left,
.carousel-slide-actions .carousel-arrow-right {
  position: absolute;
  top: 50%;
  font-size: var(--font-size-xl);
  /* background: rgb(255 255 255 / 60%); */
  background: var(--translucent-bg-white);
  width: 30px;
  height: 30px;
  line-height: 15px;
  border: 0;
  border-radius: var(--border-radius-circle);
  cursor: pointer;
  color: var(--primary-txt-color-light);
}

.carousel-arrow-left {
  left: 1rem;
}

.carousel-arrow-right {
  right: 1rem;
}
