.custom-checkbox-label {
  position: relative;
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
  font-size: 14px;
}

.custom-checkbox-label input {
  margin: 0 16px 0 0;
  visibility: hidden;
}

.custom-checkbox-indicator,
.custom-checkbox-indicator.no-selected {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c2c9;
  background: #fff;
  border-radius: 2px;
}

.custom-checkbox-indicator:after,
.custom-checkbox-indicator.all-selected:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  width: 8px;
  height: 5px;
  border-color: #fff;
  border-style: none none solid solid;
  border-width: 2px;
  transition: all 0.3s ease-in-out;
  transform: rotate(-45deg);
}

.custom-checkbox-label input:checked ~ .custom-checkbox-indicator {
  border: none;
  background: #ff3f6c;
}

.custom-checkbox-label
  input:checked
  ~ .custom-checkbox-indicator.some-selected:after {
  height: 0px;
  top: 7px;
  transform: rotate(0deg);
}
