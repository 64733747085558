.login-with-password-main-container {
  height: var(--main-content-div-full-height);
  width: 100%;
  background: linear-gradient(to bottom right, #feedf6, #fcf0e2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  width: 400px;
  background-color: #fff;
  color: #282c3f;
  padding: var(--spacer-xl);
}

.login-box h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: var(--spacer-lg);
}

.form-container .form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 0;
  font-size: 13px;
  line-height: 1.42857143;
  color: #3e4152;
  background-color: #fff;
  background-image: none;
  border: none;
}

.form-container .form-group .form-control {
  border: 1px solid #d4d5d9;
  font-size: 12px;
  padding: 11px 12px;
  height: auto;
  line-height: 16px;
  color: #282c3f;
}

.form-container .form-group input {
  caret-color: #ff3f6c;
}

.form-container .form-group input:focus {
  transition: 0.4s;
  border: 1px solid #282c3f;
}

.form-container .form-group .top-placeholder-alternative {
  color: #94969f;
  top: -9px;
  left: 12px;
  font-size: 12px;
  padding: 0 2px;
  position: absolute;
  background-color: #fff;
}

.form-container .form-group input:focus ~ .top-placeholder-alternative {
  color: #282c3f;
}

.form-container .form-group .placeholder-alternative {
  color: #94969f;
  top: 12px;
  left: 12px;
  font-size: 12px;
  position: absolute;
  pointer-events: none;
}

.form-container .form-group input:focus ~ .placeholder-alternative {
  transition: 0.2s;
  top: -9px;
  background-color: #fff;
  padding: 0 2px;
  color: #282c3f;
}

.form-container .form-group .top-placeholder-alternative span,
.form-container .form-group .placeholder-alternative span {
  color: var(--accent-red-color);
}

.form-group .form-control-feedback {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  font-size: 11px;
  line-height: 34px;
  text-align: center;
  color: #ff3e6c;
  font-weight: 700;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  outline: 0;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}

.btn.primary {
  color: #fff;
  background-color: #ff3e6c;
  border-color: #ff2459;
}

.btn.lg {
  padding: 10px 16px;
  font-size: 13px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.btn.block {
  display: block;
  width: 100%;
}

.form-container .submit-button {
  margin-top: 4px;
  padding: 11px 16px;
  font-size: 14px;
  letter-spacing: 0.6px;
  border-radius: 2px;
  line-height: 16px;
}

.auth-page-links {
  color: #282c3f;
  font-size: 12px;
  margin-top: 30px;
}

.auth-page-link {
  cursor: pointer;
  color: #ff3f6c;
  font-weight: 700;
  margin-left: var(--spacer-xs);
}
