.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 40%;
  max-width: 80%;
  max-height: 85vh;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  transform: translate(-50%, -50%);
  border: 1px solid #d4d5d9;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #535766;
  font-weight: 700;
  padding: 20px 10px 20px 16px;
  background: #fff;
  border-bottom: 1px solid #d5d6d9;
  text-transform: uppercase;
}

.modal-content {
  padding: 10px 16px;
  overflow-y: auto;
  flex-grow: 1;
  max-height: calc(85vh - 100px); /* Adjust the calculation */
}

.modal-footer {
  margin-top: auto;
  text-align: right;
  background: #fff;
  padding: 8px;
  box-shadow: 0 -1px 4px 0 #0000004d;
  border-top: 1px solid #d4d5d9;
}

.modal-footer button {
  border-radius: 4px;
  padding: 14px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #ff3f6c;
  cursor: pointer;
  border: none;
  letter-spacing: 1px;
  display: block;
  width: 100%;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
