.carousel-container {
  position: relative;
}

.items-wrapper {
  display: flex;
  gap: 1rem;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.item-container {
  min-width: 230px;
  max-width: 230px;
  height: 280px;
}

.item-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pre-btn,
.next-btn {
  position: absolute;
  top: 50%;
  font-size: var(--font-size-xl);
  background: rgb(255 255 255 / 60%);
  width: 30px;
  height: 30px;
  line-height: 25px;
  border: 0;
  border-radius: var(--border-radius-circle);
  cursor: pointer;
  text-align: center;
}

.pre-btn {
  left: 1rem;
}

.next-btn {
  right: 1rem;
}
