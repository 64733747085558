.cart-item-box {
  position: relative;
  background: #fff;
  font-size: 14px;
  border: 1px solid #eaeaec;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: var(--spacer-md);
}

.item-select-checkbox {
  position: absolute;
  z-index: 1;
  width: auto;
  top: 20px;
  left: 20px;
}

.cart-item-image-and-details-box {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
}

.cart-item-thumbnail-image {
  --img-aspect-ratio: 1 / 3.6;
  width: 115px;
  background: rgb(229, 241, 255);
}

.cart-item-details {
  flex: 1;
}

.item-brand,
.item-link,
.item-name {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
}

.item-seller-data {
  font-size: 12px;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 62%;
  color: #94969f;
}

.size-and-qty-box {
  display: flex;
  display: flex;
  gap: 10px;
  margin: 8px 0;
}

.return-order-box {
  display: inline-flex;
  font-size: 12px;
  padding-top: 8px;
}

.return-order-icon {
  margin-top: 2px;
}

.return-days {
  font-weight: 700;
  margin: 0 2px 0 6px;
}

.delivery-period {
  color: #282c3f;
  font-size: 12px;
  letter-spacing: -0.1px;
  margin-bottom: 8px;
  line-height: 15px;
  white-space: pre-wrap;
}

.delivery-period span {
  font-weight: 700;
}

.cancel-item-btn {
  position: absolute;
  top: 13px;
  right: 13px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
